import { api } from "../../../../api"
import { setAlertFunc } from "../../../../components/AlertToaster/AlertSlice"
import { IFile, IResponse, StatusButton, StatusTabsData, TicketDataResponseType } from "../../../Common/commonTypes"

const MODULE_URL = "operations"

type OpsCompletionDateObj = {
    completion_date: string
    timestamp: string
    user_id: number
    username: string
}

export type CardData = {
    assigned_to: string
    assigned_to_id: number
    display_id: string
    status: string
    status_color_code: string
    text_color_code: string
    ticket_id: number
    ticket_type: string
    survey_report_url?: any
    data?: any
    solo_logistics_tickets: any
    solo_data?: any
    card_name: string
    main_ticket_dr?: string
    progress: number
}

export type DocHubObj = {
    comment_id?: null
    doc_name: string
    doc_type: string
    file_url: IFile
    is_comment: boolean
    remarks: string
    stage: string
    uploaded_on: string
}

const installationService = api.injectEndpoints({
    endpoints: build => ({
        getInstallationTabs: build.query({
            query: params => ({
                url: `${MODULE_URL}/ticket/total`,
                params: {
                    state_id: JSON.stringify(params.data.stateId ?? []),
                    status_id: JSON.stringify(params.data.statusId ?? []),
                    city_id: JSON.stringify(params.data.cityId ?? []),
                    assigned_to: params.data.assigned_to?.id ?? "",
                    deal_done_by_id: params.data.deal_done_by_id?.id ?? "",
                    age: JSON.stringify(params.data.age ?? []),
                    age_op: params.data.age_op ?? "",
                    raised_on_end: params.data.TicketEndDate ?? "",
                    raised_on_start: params.data.TicketStartDate ?? "",
                    updated_on_start: params.data.UpdateStartDate ?? "",
                    updated_on_end: params.data.UpdateEndDate ?? "",
                    charger_type: JSON.stringify(params.data.chargerIds ?? []),
                    vendor_ids: JSON.stringify(params.data.vendor_ids ?? []),
                    search_key: params.data.search_key ?? "",
                    raised_by_id: params.data.raised_by_id?.id ?? "",
                    blocked_on: JSON.stringify(params.data.blocked_on ?? []),
                    read_filter: params.data.ReadUnread ?? "all",
                    per_page: params?.selectedValue,
                    page: params?.page,
                },
            }),
            transformResponse: (response: { message: StatusTabsData[] }) => response.message,
            providesTags: ["Installation"],
        }),
        getInstallationData: build.query<TicketDataResponseType, { data: any; selectedValue: number; page: number }>({
            query: params => ({
                url: `${MODULE_URL}/ticket/${params.data.parent_status ?? "all_tab"}`,
                params: {
                    state_id: JSON.stringify(params.data.stateId ?? []),
                    status_id: JSON.stringify(params.data.statusId ?? []),
                    city_id: JSON.stringify(params.data.cityId ?? []),
                    assigned_to: params.data.assigned_to?.id ?? "",
                    deal_done_by_id: params.data.deal_done_by_id?.id ?? "",
                    age: JSON.stringify(params.data.age ?? []),
                    age_op: params.data.age_op ?? "",
                    raised_on_end: params.data.TicketEndDate ?? "",
                    raised_on_start: params.data.TicketStartDate ?? "",
                    updated_on_start: params.data.UpdateStartDate ?? "",
                    updated_on_end: params.data.UpdateEndDate ?? "",
                    charger_type: JSON.stringify(params.data.chargerIds ?? []),
                    vendor_ids: JSON.stringify(params.data.vendor_ids ?? []),
                    search_key: params.data.search_key ?? "",
                    raised_by_id: params.data.raised_by_id?.id ?? "",
                    blocked_on: JSON.stringify(params.data.blocked_on ?? []),
                    read_filter: params.data.ReadUnread ?? "all",
                    per_page: params?.selectedValue,
                    page: params?.page,
                },
            }),
            providesTags: ["Installation"],
        }),
        getRaisedByUsers: build.query<{ id: number; name: string }[], void>({
            query: () => ({
                url: `${MODULE_URL}/raised_by_users`,
            }),
            providesTags: ["Installation"],
        }),
        getInstallationDealDetails: build.query<any, { ticket_id: string }>({
            query: ({ ticket_id }) => ({
                url: `${MODULE_URL}/ops_ticket_detail`,
                params: {
                    ticket_id,
                },
            }),
            providesTags: (result, error, arg) => ["Installation", { type: "Installation", id: arg.ticket_id }],
        }),
        getInstallationOtherDetails: build.query<
            { cards: CardData[]; images: { images: any[] } },
            { ticket_id: string }
        >({
            query: ({ ticket_id }) => ({
                url: `${MODULE_URL}/other_details`,
                params: {
                    ticket_id,
                },
            }),
            providesTags: (result, error, arg) => ["Installation", { type: "Installation", id: arg.ticket_id }],
        }),
        getOpsButtonDropdown: build.query<StatusButton[], { ticket_id: string; sub_module: string; role_id: string }>({
            query: params => ({
                url: `${MODULE_URL}/button_dropdown`,
                params,
            }),
            providesTags: ["Installation", "EvApplication"],
        }),
        getOpsCompletionDate: build.query<OpsCompletionDateObj[], { ticket_id: string }>({
            query: params => ({
                url: `${MODULE_URL}/completion_date`,
                params,
            }),
            providesTags: (result, error, arg) => [{ type: "Installation", id: arg.ticket_id }],
        }),
        getOpsCost: build.query<any, { ticket_id: string }>({
            query: ({ ticket_id }) => ({
                url: `${MODULE_URL}/costs`,
                params: {
                    ticket_id
                }
            }),
            providesTags: (result, error, arg) => [{ type: "Installation", id: arg.ticket_id }]
        }),
        // For dochub
        getDocHubCategories: build.query<{ id: number; name: string }[], void>({
            query: () => ({
                url: `dochub_category`,
            }),
            transformResponse: (response: IResponse<{ id: number; name: string }[]>) => response.data,
        }),
        getOpsTicketStage: build.query<{ id: number; status: string }[], void>({
            query: () => ({
                url: `${MODULE_URL}/ticket_stage`,
            }),
            transformResponse: (response: IResponse<{ id: number; status: string }[]>) => response.data,
        }),
        getDochubData: build.query<DocHubObj[], { ticket_id: number; ticket_stage: string; doc_type: string }>({
            query: params => ({
                url: `${MODULE_URL}/dochub`,
                params,
            }),
            transformResponse: (response: IResponse<DocHubObj[]>) => response.data,
            providesTags: ["Installation"],
        }),
        getLinkSurveyList: build.query<IResponse<{ id: number, name: string }[]>, void>({
            query: () => ({
                url: `${MODULE_URL}/survey/link_survey`,
            }),
            providesTags: (result, error, arg) => ["Installation"]
        }),

        // Mutations
        addDocHubData: build.mutation<IResponse<string>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/dochub`,
                method: "POST",
                body
            }),
            invalidatesTags: ["Installation"],
        }),

        addSiteSurveyCompletionDate: build.mutation<IResponse<string>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/delivery_date`,
                method: "POST",
                body
            }),
            invalidatesTags: (result, error, arg) => [{ type: "Installation", id: arg.get("ticket_id") as string }, "Installation"]
        }),

        siteReadyForDispatch: build.mutation<IResponse<string>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/site_ready_for_dispatch`,
                method: "PUT",
                body
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    dispatch(setAlertFunc({ type: "success", msg: data.data }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error.message }))
                }
            },
            invalidatesTags: (result, error, arg) => [{ type: "Installation", id: arg.get("ticket_id") as string }, "Installation"]
        }),

        requestSiteSurvey: build.mutation<any, FormData>({
            query: body => ({
                url: `${MODULE_URL}/survey/request_survey`,
                method: "POST",
                body
            }),
            invalidatesTags: (result, error, arg) => [{ type: "Installation", id: arg.get("ops_ticket_id") as string }, "Installation"]
        }),

        editOpsCompletionDate: build.mutation<{ msg: string }, FormData>({
            query: body => ({
                url: `${MODULE_URL}/completion_date`,
                method: "PUT",
                body: body,
            }),
            async onQueryStarted(arg, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    dispatch(setAlertFunc({ type: "success", msg: data?.msg }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error.message }))
                }
            },
            invalidatesTags: (result, error, arg) => [{ type: "Installation", id: arg.get("ticket_id") as string }],
        }),

        editOpsTicketDetails: build.mutation<any, FormData>({
            query: body => ({
                url: `${MODULE_URL}/ticket`,
                method: "PUT",
                body
            }),
            invalidatesTags: (result, error, arg) => [{ type: "Installation", id: arg.get("ticket_id") as string }, "Installation"]
        }),
        
        editLinkSiteSurvey: build.mutation({
            query: body => ({
                url: `${MODULE_URL}/survey/link_survey`,
                method: "PUT",
                body
            }),
            invalidatesTags: (result, error, arg) => [{ type: "Installation", id: arg.get("ticket_id") as string }, "Installation"]
        })
    }),
})

export const {
    useLazyGetInstallationTabsQuery,
    useLazyGetInstallationDataQuery,
    useGetRaisedByUsersQuery,
    useGetInstallationDealDetailsQuery,
    useGetInstallationOtherDetailsQuery,
    useGetOpsButtonDropdownQuery,
    useGetOpsCompletionDateQuery,
    useGetOpsCostQuery,
    useGetDocHubCategoriesQuery,
    useGetOpsTicketStageQuery,
    useGetDochubDataQuery,
    useGetLinkSurveyListQuery,

    // Mutations
    useAddDocHubDataMutation,
    useAddSiteSurveyCompletionDateMutation,
    useSiteReadyForDispatchMutation,
    useEditOpsCompletionDateMutation,
    useEditOpsTicketDetailsMutation,
    useEditLinkSiteSurveyMutation,
    useRequestSiteSurveyMutation
} = installationService

export { installationService }
