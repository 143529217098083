import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import { api } from "../../api"
import { TStore } from "../../store/Store"
import { getTagType } from "../../utils/utils"
import {
    BlockedReasons,
    BulkLabelPayload,
    CategoryObj,
    ChargerDetailsData,
    ChargerTypeObj,
    CPOResponseType,
    HistoryData,
    IFile,
    IResponse,
    LabelResponse,
    TagTypes,
    UserInfo,
} from "./commonTypes"
import { setAlertFunc } from "../../components/AlertToaster/AlertSlice"
import { resetBulkMappingStates } from "../Operations/OperationSlice"

const commonService = api.injectEndpoints({
    endpoints: build => ({
        getStates: build.query<{ id: number; name: string }[], void>({
            query: () => ({
                url: `states`,
            }),
        }),
        getCities: build.query<{ id: number; name: string }[], { state_id: number[] }>({
            query: ({ state_id }) => ({
                url: `cities`,
                params: {
                    state_id: state_id.join(","),
                },
            }),
        }),
        // Query to get charger details associated with a ticket
        getChargerDetails: build.query<ChargerDetailsData[], { ticket_id: string; ticket_type: string }>({
            query: params => ({
                url: `charger_details`,
                params: {
                    ticket_id: params.ticket_id,
                    ticket_type: params.ticket_type,
                },
            }),
            transformResponse: (response: IResponse<ChargerDetailsData[]>) => response.data,
            providesTags: (result, error, arg) => [
                { type: getTagType(arg.ticket_type) as TagTypes, id: arg.ticket_id },
            ],
        }),
        // Query to get all charger types available
        getChargersType: build.query<ChargerTypeObj[], void>({
            query: () => ({
                url: `chargers`,
            }),
        }),
        // Query to get all statuses of a particular module
        getAllStatus: build.query<{ id: number; name: string }[], { mod_id: number; sub_module: string }>({
            query: params => ({
                url: `childstatus`,
                params: params,
            }),
        }),
        // Query to fetch OEM filter options
        getOEM: build.query<IResponse<any>, { search_key?: string; category_id?: number | "" }>({
            query: () => ({
                url: `oem`,
            }),
        }),
        // Query to fetch CPO filter options
        getCPO: build.query<CPOResponseType, { search_key?: string; category_id?: number | "" }>({
            query: () => ({
                url: `onboarding/host/listcpo`,
            }),
        }),
         getReasons: build.query<{ id: number; name: string, reason:String,status:boolean,reason_type:string }[], { module_id: number; submodule_id: number }>({
            query: params => ({
                url: `settings/reasons`,
                params: params,
            }),
        }),
        // Query to fetch assigned-to filter options
        getAssignedToFilterOptions: build.query<
            Partial<UserInfo[]>,
            { search_key: string; ticket_id: string; module_id: number; submodule_id: number | "" }
        >({
            queryFn: async (params, { getState }, extraOptions, baseQuery) => {
                const state = getState() as TStore
                const role_id = state?.verifyLogin?.loginData?.user_data?.role_id

                const response = await baseQuery({
                    url: `get_assign_to`,
                    params: {
                        ...params,
                        role_id: role_id ?? "",
                    },
                })

                if (response.error) {
                    return { error: response.error as FetchBaseQueryError }
                }

                return { data: response.data as Partial<UserInfo[]> }
            },
        }),
        // Query to fetch assigned-to list for progress component
        getAssignedToListOptions: build.query<
            Partial<UserInfo[]>,
            { search_key: string; ticket_id: string; module_id: number; submodule_id?: number }
        >({
            queryFn: async (params, { getState }, extraOptions, baseQuery) => {
                const state = getState() as TStore
                const role_id = state?.verifyLogin?.loginData?.user_data?.role_id

                const response = await baseQuery({
                    url: `comments/assign_to`,
                    params: {
                        ...params,
                        submodule_id: params.submodule_id ?? "",
                        role_id: role_id ?? "",
                    },
                })

                if (response.error) {
                    return { error: response.error as FetchBaseQueryError }
                }

                return { data: response.data as Partial<UserInfo[]> }
            },
        }),
        getVendorList: build.query<{ id: number; name: string }[], { search_key?: string; category_id?: number | "" }>({
            query: ({ search_key = "", category_id = "" }) => ({
                url: `third_party/vendor_list`,
                params: {
                    search_key,
                    category_id,
                },
            }),
        }),
        getCategories: build.query<
            CategoryObj[],
            { module_id: number; is_visible?: boolean; team_id?: number; modules_flag?: boolean }
        >({
            query: params => ({
                url: `categories`,
                params: {
                    ...params,
                    is_visible: params?.is_visible ? "True" : params.module_id === 2 ? "False" : "True",
                },
            }),
        }),
        // Query to fetch charger SKUs and name, id
        getChargerSku: build.query<{ charger_type_id: number; name: string; sku: string }[], void>({
            query: () => ({
                url: `get_charger_skus`,
            }),
        }),
        // Query to fetch time of day
        getTimeOfDay: build.query<IResponse<{ id: number; name: string }[]>, void>({
            query: () => ({
                url: `time_of_day`,
            }),
        }),

        getDealDoneByUsers: build.query<{ id: number; name: string }[], void>({
            query: () => ({
                url: `operations/deal_done_by_users`,
            }),
        }),

        getDocumentTemplates: build.query<IResponse<IFile>, { category_id: string }>({
            query: ({ category_id }) => ({
                url: `document_templates`,
                params: {
                    category_id
                }
            }),
        }),

        // For Blocked reasons list
        getReasonsForBlocking: build.query<BlockedReasons[], { module_id: string, reason_type: string, submodule_id: string | number }>({
            query: params => ({
                url: `settings/blocked_reasons`,
                params: params
            }),
            providesTags: ["Settings"]
        }),

        // For ops ticket history
        getOpsHistory: build.query<HistoryData[], { ticket_id: string, sub_module: string }>({
            query: params => ({
                url: `operations/ticket_history`,
                params
            }),
            providesTags: (result, error, arg) => [getTagType(arg.sub_module) as TagTypes]
        }),

        // Get all labels
        getAllLabels: build.query<LabelResponse, void>({
            query: () => ({
                url: `label`,
            }),
            providesTags: ["Labels"],
        }),

        // Labels for a particular ticket
        getLabels: build.query<
            LabelResponse,
            { ticket_id?: number | ''; module_id: number; submodule_id: number; search_term?: string }
        >({
            query: params => ({
                url: `label`,
                params: {
                    ...params,
                },
            }),
            providesTags: ["Labels"],
        }),
        // Mutation to change the primary tag
        updatePrimaryLabel: build.mutation<
            any,
            {
                label_id: number
                is_primary: 1 | 0
                ticket_id: number
                module_id: number
                submodule_id: number
            }
        >({
            query: body => ({
                url: `label/sequence`,
                method: "PUT",
                body,
            }),
            invalidatesTags: ["Labels"],
        }),
        // Mutation to create or map labels to a particular ticket
        mapLabels: build.mutation<IResponse<string>, { data: any }>({
            query: ({ data }) => ({
                url: `label/create_or_map`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Labels"],
        }),
        // Mutation to clear labels
        clearLabels: build.mutation<IResponse<string>, { data: any }>({
            query: ({ data }) => ({
                url: `label/remove`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ["Labels"],
        }),

        deleteLabelMutation: build.mutation<{ message: string, status: string }, { id: number }>({
            query: ({ id }) => ({
                url: `label/${id}`,
                method: "DELETE",
            }),
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled
                    dispatch(setAlertFunc({ type: "success", msg: data?.message }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error?.message }))
                }
            },
            invalidatesTags: ["Labels"]
        }),

        // Mutation for bulk mapping to labels to multiple ticket
        updateLabelsInBulk: build.mutation<{ message: string, status: string }, BulkLabelPayload[]>({
            query: payload => ({
                url: `label/map`,
                method: "POST",
                body: {
                    data: payload
                }
            }),
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled
                    dispatch(setAlertFunc({ type: "success", msg: data?.message }))
                    dispatch(resetBulkMappingStates())
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error?.message }))
                }
            },
            invalidatesTags: ["Labels"]
        }),

        // Mutation to update charger details for a particular ticket
        updateChargerDetails: build.mutation<
            IResponse<string>,
            { data: { ticket_id: string; ticket_type: string; data: Partial<ChargerDetailsData[]> } }
        >({
            query: ({ data }) => ({
                url: `charger_details`,
                method: "PUT",
                body: data,
            }),
            invalidatesTags: (result, error, arg) => [getTagType(arg.data.ticket_type) as TagTypes, "Logistics"],
        }),
    }),
})

export const {
    useLazyGetChargerDetailsQuery,
    useGetStatesQuery,
    useGetCitiesQuery,
    useGetChargersTypeQuery,
    useGetAllStatusQuery,
    useGetAssignedToFilterOptionsQuery,
    useGetAssignedToListOptionsQuery,
    useGetVendorListQuery,
    useGetCategoriesQuery,
    useGetChargerSkuQuery,
    useGetReasonsQuery,
    useGetTimeOfDayQuery,
    useGetOEMQuery,
    useGetCPOQuery,
    useGetDocumentTemplatesQuery,
    useGetReasonsForBlockingQuery,
    useGetOpsHistoryQuery,
    useGetAllLabelsQuery,
    useLazyGetLabelsQuery,
    useUpdatePrimaryLabelMutation,
    useGetDealDoneByUsersQuery,
    useDeleteLabelMutationMutation,
    useUpdateLabelsInBulkMutation,
    useUpdateChargerDetailsMutation,
    useMapLabelsMutation,
    useClearLabelsMutation,
} = commonService
