import { axiosInstance } from "../Auth/components/axiosInterceptors"
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { setAlertFunc } from "../../components/AlertToaster/AlertSlice"
import urls from "../../enums/urlList"
import axios from "axios"
import { submitFinalUploads } from "../../utils/utils"
import { installationService } from "./features/Installation/installationService"

const initialState = {
    EvApplicationLoading: false,
    EvApplication: [],
    EVDataLoading: false,
    EVTabsLoading: false,
    EVTabsData: [],
    EVData: [],
    installationFormValue: {
        cityId: [],
        stateId: [],
        chargerIds: [],
        statusId: [],
        age_op: "",
        age: [],
        more_than_age: "",
        between_age1: "",
        between_age2: "",
        less_than_age: "",
        assigned_to: "",
        TicketStartDate: "",
        TicketEndDate: "",
        UpdateStartDate: "",
        UpdateEndDate: "",
        search_key: "",
        vendor_ids: [],
        deal_done_by_id: "",
        raised_by_id: "",
        blocked_on: [],
        ReadUnread: "all",
        parent_status: "all_tab",
    },
    currentRules: [],
    displayValues: [],
    chargerDisplayValues: [],
    chargerCurrentRules: [],
    vendorDisplayValues: [],
    vendorCurrentRules: [],
    StateDisplayValues: [],
    StateCurrentRules: [],
    CityDisplayValues: [],
    CityCurrentRules: [],
    blockedOnDisplayValues: [],
    blockedOnCurrentValues: [],
    // ticketRaisedByDisplayValues: [],
    // ticketRaisedByCurrentValues: [],
    EvApplicationFormValue: {
        cityId: [],
        stateId: [],
        chargerIds: [],
        statusId: [],
        age_op: "",
        age: [],
        more_than_age: "",
        between_age1: "",
        between_age2: "",
        less_than_age: "",
        assigned_to: "",
        TicketStartDate: "",
        TicketEndDate: "",
        UpdateStartDate: "",
        vendor_ids: [],
        UpdateEndDate: "",
        search_key: "",
        ReadUnread: "all",
        ticketType: "all",
        parent_status: "all_tab",
    },
    EvCurrentRules: [],
    EvDisplayValues: [],
    EvChargerDisplayValues: [],
    EvChargerCurrentRules: [],
    EvStateDisplayValues: [],
    EvStateCurrentRules: [],
    EvCityDisplayValues: [],
    EvCityCurrentRules: [],
    DealDetailsLoading: false,
    DealDetailsSuccess: false,
    DealDetailsData: [],
    EvApplicationDetailLoading: false,
    EvApplicationDetail: [],
    AssignedToLoading: false,
    AssignedToData: [],
    AssignedToEditLoading: false,
    DealButtonLoading: false,
    DealButtonData: [],
    comments: [],
    commentsLoading: false,
    VendorListLoading: false,
    VendorList: [],
    VendorCabelingList: [],
    VendorStandList: [],
    VendorNormalList: [],
    editSurveyLoading: false,
    editSurveySuccess: false,
    editSiteDispatchLoading: false,
    editSiteDispatchSuccess: false,
    addSiteDeliveryDateSuccess: false,
    addSiteDeliveryDateLoading: false,
    HistoryLoading: false,
    opsHistoryData: [],
    is_add_ticket_loading: false,
    is_add_ticket_success: false,
    is_add_ev_ticket_loading: false,
    is_add_ev_ticket_success: false,
    editProcLoading: false,
    editProcSuccess: false,
    CostData: [],
    CostLoading: false,
    PriceHistoryLoading: false,
    priceHistoryData: [],
    priceTotalCost: "",
    ScopeStatusData: [],
    ScopeStatusLoading: false,
    SkipStatusLoading: false,
    SkipStatusData: [],
    ButtonDropdownData: [],
    ButtonDropdownLoading: false,
    ButtonDropdownSuccess: false,
    editSkipStatusSuccess: false,
    editSkipStatusLoading: false,
    editTicketDetailsLoading: false,
    BlockedReasonsLoading: false,
    BlockedReasonsData: [],
    addTicketFormValue: {
        project_name: "",
        city_id: "",
        state_id: "",
        deal_done_by_id: "",
        deal_closure_date: "",
        is_location_fixed: "",
        spoc_name: "",
        spoc_phone: "",
        spoc_address: "",
        hosting_address: "",
        chargers: [],
        latitude: "",
        longitude: "",
        is_ev_application_req: "",
        // is_trans_hpole_available: '',
        scope_of_work: "",
        sow_group_ids: "",
        property: "",
        station_type: "",
        total_spot: "",
    },
    addEVTicketFormValue: {
        project_name: "",
        city_id: "",
        state_id: "",
        deal_closure_date: "",
        property_type: "",
        chargers: [],
        rentAgreementReport: "",
        propertyProof: "",
        other_documents: "",
        electricityBill: "",
        noc: "",
        agreementReport: "",
        hoisting_address: "",
        pincode: "",
        latitude: "",
        longitude: "",
        site_id: "",
        access_type: "",
    },
    addopsCommentLoading: false,
    addOpsCommentSuccess: false,
    editopsCommentLoading: false,
    editOpsCommentSuccess: false,
    deleteopsCommentLoading: false,
    deleteOpsCommentSuccess: false,
    InstallDealTab: 0,
    EVSelectedTab: 0,
    selectedTab: 0,
    DeliveryTemplateLoading: false,
    DeliveryTemplateData: [],
    totalPages: 1,
    page: 1,
    EvPage: 1,
    selectedValue: 10,
    HoistingLoading: false,
    HoistingData: [],
    evAutofillLoading: false,
    evAutofillData: [],
    OtherDetailsLoading: false,
    OtherDetailsData: [],
    editOtherDetailsLoading: false,
    editOtherDetailSuccess: false,
    addDRDetailsLoading: false,
    addDRDetailsSuccess: false,
    editRequestLinkLoading: false,
    editRequestLinkSuccess: false,
    editLinkSurveyLoading: false,
    editLinkSurveySuccess: false,
    editVendorLoading: false,
    editVendorSuccess: false,
    DemolitionLoading: false,
    DemolitionTabsLoading: false,
    DemolitionTabsData: [],
    DemolitionData: [],
    DemolitionSelectedTab: 0,
    DemolitionFormValue: {
        cityId: [],
        stateId: [],
        chargerIds: [],
        statusId: [],
        age_op: "",
        age: [],
        more_than_age: "",
        between_age1: "",
        between_age2: "",
        less_than_age: "",
        assigned_to: "",
        TicketStartDate: "",
        TicketEndDate: "",
        UpdateStartDate: "",
        UpdateEndDate: "",
        search_key: "",
        vendor_ids: [],
        deal_done_by_id: "",
        raised_by_id: "",
        ReadUnread: "all",
        parent_status: "demo_open",
    },
    addDemolitionTicketForm: {
        project_name: "",
        site_id: "",
        latitude: "",
        longitude: "",
        address: "",
        city: "",
        state: "",
        pincode: "",
        poc_name: "",
        poc_contact: "",
        poc_email: "",
        closure_document: "",
        cancelled_cheque: "",
    },
    is_add_Demolition_ticket_loading: false,
    is_add_Demolition_ticket_success: false,
    dealDoneByCurrentValues: [],
    dealDoneByDisplayValues: [],
    raisedByCurrentValues: [],
    raisedByDisplayValues: [],
    demolitionChargerLoading: false,
    demolitionChargerData: [],
    isBulkMode: false,
    bulkSelectedTicketIds: [] as number[],
    isBulkSelectAll: false
}

export const getEvApplication: any = createAsyncThunk("EvApplication", async (obj: any, thunkApi) => {
    try {
        const response: any = await axiosInstance(`operations/ev_application`, {
            method: "GET",
            params: {
                state_id: JSON.stringify(obj.stateId ?? []),
                status_id: JSON.stringify(obj.statusId ?? []),
                city_id: JSON.stringify(obj.cityId ?? []),
                assigned_to_id: obj.assigned_to ?? "",
                age: JSON.stringify(obj.age ?? []),
                age_op: obj.age_op ?? "",
                raised_on_end: obj.TicketEndDate ?? "",
                raised_on_start: obj.TicketStartDate ?? "",
                updated_on_start: obj.UpdateStartDate ?? "",
                updated_on_end: obj.UpdateEndDate ?? "",
                charger_type: JSON.stringify(obj.chargerIds ?? []),
                search_key: obj.search_key ?? "",
                read_filter: obj.ReadUnread ?? "all",
                ev_ticket_type: obj.ticketType ?? "all",
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})

export const getEVTabs: any = createAsyncThunk("EVTabs", async (obj: any, thunkApi) => {
    try {
        const response: any = await axiosInstance(`operations/ev_total_tickets`, {
            method: "GET",
            params: {
                state_id: JSON.stringify(obj.data.stateId ?? []),
                status_id: JSON.stringify(obj.data.statusId ?? []),
                city_id: JSON.stringify(obj.data.cityId ?? []),
                assigned_to: obj.data.assigned_to?.id ?? "",
                age: JSON.stringify(obj.data.age ?? []),
                age_op: obj.data.age_op ?? "",
                raised_on_end: obj.data.TicketEndDate ?? "",
                raised_on_start: obj.data.TicketStartDate ?? "",
                updated_on_start: obj.data.UpdateStartDate ?? "",
                updated_on_end: obj.data.UpdateEndDate ?? "",
                charger_type: JSON.stringify(obj.data.chargerIds ?? []),
                ev_ticket_type: obj.data.ticketType ?? "all",
                search_key: obj.data.search_key ?? "",
                read_filter: obj.data.ReadUnread ?? "all",
                per_page: obj?.selectedValue,
                page: obj?.page,
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const getEVData: any = createAsyncThunk("EVData", async (obj: any, { thunkApi, dispatch }: any) => {
    try {
        const response: any = await axiosInstance(`operations/ev_application/${obj.data.parent_status ?? "all_tab"}`, {
            method: "GET",
            params: {
                state_id: JSON.stringify(obj.data.stateId ?? []),
                status_id: JSON.stringify(obj.data.statusId ?? []),
                city_id: JSON.stringify(obj.data.cityId ?? []),
                assigned_to: obj.data.assigned_to?.id ?? "",
                age: JSON.stringify(obj.data.age ?? []),
                age_op: obj.data.age_op ?? "",
                raised_on_end: obj.data.TicketEndDate ?? "",
                raised_on_start: obj.data.TicketStartDate ?? "",
                updated_on_start: obj.data.UpdateStartDate ?? "",
                updated_on_end: obj.data.UpdateEndDate ?? "",
                charger_type: JSON.stringify(obj.data.chargerIds ?? []),
                ev_ticket_type: obj.data.ticketType ?? "all",
                search_key: obj.data.search_key ?? "",
                read_filter: obj.data.ReadUnread ?? "all",
                per_page: obj?.selectedValue,
                page: obj?.page,
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})

export const getDemolitionTabs: any = createAsyncThunk("getDemolitionTabs", async (obj: any, thunkApi) => {
    try {
        const response: any = await axiosInstance(`operations/demolitions/ticket_total`, {
            method: "GET",
            params: {
                state_id: JSON.stringify(obj.data.stateId ?? []),
                status_ids: JSON.stringify(obj.data.statusId ?? []),
                city_id: JSON.stringify(obj.data.cityId ?? []),
                deal_done_by_id: JSON.stringify(obj.data.deal_done_by_id ?? []),
                assigned_to: obj.data.assigned_to?.id ?? "",
                age: JSON.stringify(obj.data.age ?? []),
                age_op: obj.data.age_op ?? "",
                raised_on_end: obj.data.TicketEndDate ?? "",
                raised_on_start: obj.data.TicketStartDate ?? "",
                updated_on_start: obj.data.UpdateStartDate ?? "",
                updated_on_end: obj.data.UpdateEndDate ?? "",
                charger_ids: JSON.stringify(obj.data.chargerIds ?? []),
                vendor_ids: JSON.stringify(obj.data.vendor_ids ?? []),
                search_key: obj.data.search_key ?? "",
                raised_by: JSON.stringify(obj.data.raised_by_id ?? []),
                read_filter: obj.data.ReadUnread ?? "all",
                per_page: obj?.selectedValue,
                page: obj?.page,
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const getDemolition: any = createAsyncThunk("getDemolition", async (obj: any, { thunkApi, dispatch }: any) => {
    try {
        const response: any = await axiosInstance(`operations/demolitions/${obj.data.parent_status ?? "demo_open"}`, {
            method: "GET",
            params: {
                state_id: JSON.stringify(obj.data.stateId ?? []),
                status_ids: JSON.stringify(obj.data.statusId ?? []),
                city_id: JSON.stringify(obj.data.cityId ?? []),
                deal_done_by_id: JSON.stringify(obj.data.deal_done_by_id ?? []),
                assigned_to: obj.data.assigned_to?.id ?? "",
                age: JSON.stringify(obj.data.age ?? []),
                age_op: obj.data.age_op ?? "",
                charger_ids: JSON.stringify(obj.data.chargerIds ?? []),
                raised_on_end: obj.data.TicketEndDate ?? "",
                raised_on_start: obj.data.TicketStartDate ?? "",
                updated_on_start: obj.data.UpdateStartDate ?? "",
                updated_on_end: obj.data.UpdateEndDate ?? "",
                vendor_ids: JSON.stringify(obj.data.vendor_ids ?? []),
                search_key: obj.data.search_key ?? "",
                raised_by: JSON.stringify(obj.data.raised_by_id ?? []),
                read_filter: obj.data.ReadUnread ?? "all",
                per_page: obj?.selectedValue,
                page: obj?.page,
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const getDemolitionChargers: any = createAsyncThunk("getDemolitionChargers", async (obj: any, thunkApi) => {
    try {
        const response: any = await axiosInstance(`operations/demolitions/charger_details`, {
            method: "GET",
            params: {
                ops_ticket_id: obj?.ops_id ?? "",
                station_id: obj?.station_id ?? "",
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const getDealDetails: any = createAsyncThunk("DealDetails", async (ticketId: any, thunkApi) => {
    try {
        const response: any = await axiosInstance(`operations/ops_ticket_detail`, {
            method: "GET",
            params: {
                ticket_id: ticketId ?? "",
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})

export const getHoistingAddress: any = createAsyncThunk("getHoistingAddress", async (obj: any, thunkApi) => {
    try {
        const response: any = await axiosInstance(`hosting_address`, {
            method: "GET",
            params: {
                state_id: obj?.state_id ?? "",
                city_id: obj?.city_id ?? "",
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const getEvAutofillData: any = createAsyncThunk("getEvAutofillData", async (host_id: any, thunkApi) => {
    try {
        const response: any = await axiosInstance(`ev_solo_fill`, {
            method: "GET",
            params: {
                ops_ticket_id: host_id ?? "",
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const getEvApplicationDetails: any = createAsyncThunk(
    "evApplicationDetails",
    async (ticketId: any, thunkApi) => {
        try {
            const response: any = await axiosInstance(`operations/ev_application_detail`, {
                method: "GET",
                params: {
                    ticket_id: ticketId ?? "",
                },
            })
            return response.data
        } catch (error: any) {
            return thunkApi.rejectWithValue({ error: error.message })
        }
    }
)
export const getDealDetailsButton: any = createAsyncThunk("get/dealButtons", async (obj: any, thunkApi) => {
    try {
        const response: any = await axiosInstance(`operations/details_buttons`, {
            method: "GET",
            params: obj,
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const getDeliveryTemplate: any = createAsyncThunk("getDeliveryTemplate", async (category_id: any, thunkApi) => {
    try {
        const response: any = await axiosInstance(`document_templates`, {
            method: "GET",
            params: {
                category_id: category_id ?? "",
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const getVendorList: any = createAsyncThunk("getVendorList", async (thunkApi: any) => {
    try {
        const response: any = await axiosInstance(`third_party/vendor_list`, {
            method: "GET",
            params: {
                search_key: thunkApi.search_key ?? "",
                category_id: thunkApi.category_id ?? "",
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const getSurveyLinkList: any = createAsyncThunk("getSurveyLinkList", async (thunkApi: any) => {
    try {
        const response: any = await axiosInstance(`operations/survey/link_survey`, {
            method: "GET",
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const getVendorCabelingList: any = createAsyncThunk("getVendorCabelingList", async (thunkApi: any) => {
    try {
        const response: any = await axiosInstance(`third_party/vendor_list`, {
            method: "GET",
            params: {
                search_key: thunkApi.search_key ?? "",
                category_id: thunkApi.category_id ?? "",
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const getVendorStandList: any = createAsyncThunk("getVendorStandList", async (thunkApi: any) => {
    try {
        const response: any = await axiosInstance(`third_party/vendor_list`, {
            method: "GET",
            params: {
                search_key: thunkApi.search_key ?? "",
                category_id: thunkApi.category_id ?? "",
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const getVendorNormalList: any = createAsyncThunk("getVendorNormalList", async (thunkApi: any) => {
    try {
        const response: any = await axiosInstance(`third_party/vendor_list`, {
            method: "GET",
            params: {
                search_key: thunkApi.search_key ?? "",
                category_id: thunkApi.category_id ?? "",
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})

export const editEvSurvey: any = createAsyncThunk(
    "editEvSurvey",
    async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}operations/ev_application`, {
                method: "PUT",
                data: thunkApi.data,
            })
            dispatch(getEvApplicationDetails(thunkApi.data.get("ticket_id")))
            dispatch(
                getButtonDropdown({
                    ticket_id: thunkApi.data.get("ticket_id"),
                    sub_module: "applications",
                    role_id: getState()?.verifyLogin?.loginData?.user_data?.role_id,
                })
            )
            dispatch(setAlertFunc({ type: "success", msg: response?.data?.message }))
            return response.data
        } catch (error: any) {
            dispatch(setAlertFunc({ type: "error", msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message })
        }
    }
)

export const editSurvey: any = createAsyncThunk("editSurvey", async (thunkApi: any, { getState, dispatch }: any) => {
    try {
        const response: any = await axiosInstance(`${urls.base_url}operations/ticket`, {
            method: "PUT",
            data: thunkApi.data,
        })
        dispatch(setAlertFunc({ type: "success", msg: response?.data?.message }))
        dispatch(getDealDetails(thunkApi?.data.get("ticket_id")))
        dispatch(getOtherDetails(thunkApi?.data.get("ticket_id")))
        dispatch(
            getButtonDropdown({
                ticket_id: thunkApi?.data.get("ticket_id"),
                sub_module: "installations",
                role_id: getState()?.verifyLogin?.loginData?.user_data?.role_id,
            })
        )
        return response.data
    } catch (error: any) {
        dispatch(setAlertFunc({ type: "error", msg: error.message }))
        return thunkApi.rejectWithValue({ error: error.message })
    }
})

export const editVendor: any = createAsyncThunk("editVendor", async (thunkApi: any, { getState, dispatch }: any) => {
    try {
        const response: any = await axiosInstance(`${urls.base_url}operations/edit_vendor`, {
            method: "PUT",
            data: thunkApi.data,
        })
        dispatch(setAlertFunc({ type: "success", msg: response?.data?.message }))
        dispatch(getDealDetails(thunkApi?.data.get("ticket_id")))
        dispatch(
            getButtonDropdown({
                ticket_id: thunkApi?.data.get("ticket_id"),
                sub_module: "installations",
                role_id: getState()?.verifyLogin?.loginData?.user_data?.role_id,
            })
        )
        return response.data
    } catch (error: any) {
        dispatch(setAlertFunc({ type: "error", msg: error.message }))
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const editSkipStatus: any = createAsyncThunk(
    "editSkipStatus",
    async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}operations/skip_to_status`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                data: JSON.stringify(thunkApi),
            })
            dispatch(setAlertFunc({ type: "success", msg: response?.data?.message }))
            thunkApi?.sub_module === "installations"
                ? dispatch(getDealDetails(thunkApi?.ticket_id))
                : dispatch(getEvApplicationDetails(thunkApi?.ticket_id))
            dispatch(
                getButtonDropdown({
                    ticket_id: thunkApi?.ticket_id,
                    sub_module: thunkApi?.sub_module,
                    role_id: getState()?.verifyLogin?.loginData?.user_data?.role_id,
                })
            )
            return response.data
        } catch (error: any) {
            dispatch(setAlertFunc({ type: "error", msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message })
        }
    }
)

export const getHistory: any = createAsyncThunk("history", async (obj: any, thunkApi: any) => {
    try {
        const response: any = await axiosInstance(`operations/ticket_history`, {
            method: "GET",
            params: {
                ticket_id: obj.ticket_id ?? "",
                sub_module: obj.sub_module ?? "",
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})

export const getCost: any = createAsyncThunk("getCost", async (ticketId: any, thunkApi) => {
    try {
        const response: any = await axiosInstance(`operations/costs`, {
            method: "GET",
            params: {
                ticket_id: ticketId ?? "",
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const getPriceHistory: any = createAsyncThunk("priceHistory", async (obj: any, thunkApi: any) => {
    try {
        const response: any = await axiosInstance(`operations/price_history`, {
            method: "GET",
            params: {
                ticket_id: obj.ticket_id ?? "",
                sub_module: obj.sub_module ?? "",
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const getOtherDetails: any = createAsyncThunk("getOtherDetails", async (ticket_id: any, thunkApi: any) => {
    try {
        const response: any = await axiosInstance(`operations/other_details`, {
            method: "GET",
            params: {
                ticket_id: ticket_id ?? "",
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const getScopeWorkStatus: any = createAsyncThunk("getScopeWorkStatus", async (_, thunkApi) => {
    try {
        const response: any = await axiosInstance(`operations/scope_of_work`, {
            method: "GET",
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const getSkipStatuses: any = createAsyncThunk("getStatuses", async (obj: any, thunkApi) => {
    try {
        const response: any = await axiosInstance(`operations/statuses`, {
            method: "GET",
            params: {
                search_key: obj.search_key ?? "",
                ticket_id: obj.ticket_id ?? "",
                sub_module: obj.sub_module ?? "",
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const getButtonDropdown: any = createAsyncThunk("getButtonDropdown", async (obj: any, thunkApi) => {
    try {
        const response: any = await axiosInstance(`operations/button_dropdown`, {
            method: "GET",
            params: {
                ticket_id: obj.ticket_id ?? "",
                sub_module: obj.sub_module ?? "",
                role_id: obj.role_id ?? "",
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})

export const getBlockedReasons: any = createAsyncThunk("getBlockedReasons", async (obj: any, thunkApi) => {
    try {
        const response: any = await axiosInstance(`settings/blocked_reasons`, {
            method: "GET",
            params: {
                reason_type: obj.reason_type ?? "",
                module_id: obj.module_id ?? "",
                submodule_id: obj.submodule_id ?? "",
            },
        })
        return response.data
    } catch (error: any) {
        return thunkApi.rejectWithValue({ error: error.message })
    }
})

/**********************************add ticket*********************** */

export const addTicket: any = createAsyncThunk("addTicket", async (thunkApi: any, { getState, dispatch }: any) => {
    try {
        const response: any = await axiosInstance(`${urls.base_url}operations/ticket`, {
            method: "POST",
            data: thunkApi.formData,
        })
        dispatch(setAlertFunc({ type: "success", msg: response?.data?.message }))
        return response.data
    } catch (error: any) {
        dispatch(setAlertFunc({ type: "error", msg: error.message }))
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const addEVTicket: any = createAsyncThunk("addEVTicket", async (thunkApi: any, { getState, dispatch }: any) => {
    try {
        const response: any = await axiosInstance(`${urls.base_url}operations/ev_application`, {
            method: "POST",
            data: thunkApi.formData,
        })
        const currentState: any = getState()
        dispatch(
            getEVData({
                data: currentState.operation.EvApplicationFormValue,
                page: currentState.operation.page,
                selectedValue: currentState.operation.selectedValue,
            })
        )
        dispatch(
            getEVTabs({
                data: currentState.operation.EvApplicationFormValue,
                page: currentState.operation.page,
                selectedValue: currentState.operation.selectedValue,
            })
        )
        dispatch(setAlertFunc({ type: "success", msg: response?.data?.message }))
        return response.data
    } catch (error: any) {
        dispatch(setAlertFunc({ type: "error", msg: error.message }))
        return thunkApi.rejectWithValue({ error: error.message })
    }
})
export const addDemolitionTicket: any = createAsyncThunk(
    "addDemolitionTicket",
    async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}operations/demolitions/ticket`, {
                method: "POST",
                data: thunkApi.formData,
            })
            const currentState: any = getState()
            dispatch(
                getDemolition({
                    data: currentState.operation.DemolitionFormValue,
                    page: currentState.operation.page,
                    selectedValue: currentState.operation.selectedValue,
                })
            )
            dispatch(
                getDemolitionTabs({
                    data: currentState.operation.DemolitionFormValue,
                    page: currentState.operation.page,
                    selectedValue: currentState.operation.selectedValue,
                })
            )
            dispatch(setAlertFunc({ type: "success", msg: response?.data?.message }))
            return response.data
        } catch (error: any) {
            dispatch(setAlertFunc({ type: "error", msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message })
        }
    }
)

export const editTicketDetails: any = createAsyncThunk(
    "editTicketDetails",
    async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}operations/edit_deal_details`, {
                method: "PUT",
                data: thunkApi.data,
            })
            response.status === 200 && dispatch(setAlertFunc({ type: "success", msg: response?.data?.message }))
            response.status === 200 &&
                thunkApi.sub_module === "installations" &&
                dispatch(getDealDetails(thunkApi?.data.get("ticket_id")))
            response.status === 200 &&
                thunkApi.sub_module === "applications" &&
                dispatch(getEvApplicationDetails(thunkApi?.data.get("ticket_id")))
            response.status === 200 &&
                dispatch(
                    getButtonDropdown({
                        ticket_id: thunkApi?.data.get("ticket_id"),
                        sub_module: thunkApi.sub_module === undefined ? "installations" : thunkApi.sub_module,
                        role_id: getState()?.verifyLogin?.loginData?.user_data?.role_id,
                    })
                )

            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle()
            }
            return response.data
        } catch (error: any) {
            dispatch(setAlertFunc({ type: "error", msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message })
        }
    }
)
export const editOtherDetails: any = createAsyncThunk(
    "edit_other_details",
    async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}edit_documents`, {
                method: "PUT",
                data: thunkApi.data,
            })
            if (response.data.urls?.[0]?.urls) {
                let promises: Promise<any>[] = []
                let responseUrl = response.data.urls?.[0]?.urls
                responseUrl?.forEach(async (url: { presigned_url: string }, index: number) => {
                    const promise = axios.put(url.presigned_url, thunkApi?.fileData[index], {
                        headers: {
                            "Content-Type": thunkApi?.fileData[index].type,
                        },
                    })
                    promises.push(promise)
                })
                await Promise.all(promises)
                let finalPayload = {
                    ticket_id: thunkApi.data.ticket_id,
                    file_infos: response.data.urls?.[0]?.urls?.map((i: any) => ({
                        file_url: i.file_url[0],
                        report_id: i.report_id,
                        doc_id: i.doc_id,
                    })),
                }
                await submitFinalUploads(finalPayload)
            }
            response.status === 200 && dispatch(setAlertFunc({ type: "success", msg: response?.data?.message }))
            response.status === 200 &&
                thunkApi.sub_module === "installations" &&
                dispatch(installationService.util.invalidateTags([{ type: "Installation", id: thunkApi.ticket_id }]))
            response.status === 200 &&
                thunkApi.sub_module === "applications" &&
                dispatch(getEvApplicationDetails(thunkApi.data.ticket_id))

            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle()
            }
            return response.data
        } catch (error: any) {
            dispatch(setAlertFunc({ type: "error", msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message })
        }
    }
)
export const addDRDetails: any = createAsyncThunk(
    "addDRDetails",
    async (thunkApi: any, { getState, dispatch }: any) => {
        try {
            const response: any = await axiosInstance(`${urls.base_url}operations/upload_dr`, {
                method: "POST",
                data: thunkApi.data,
            })
            response.status === 200 && dispatch(setAlertFunc({ type: "success", msg: response?.data?.message }))
            response.status === 200 && dispatch(getOtherDetails(thunkApi?.data.get("ops_ticket_id")))
            response.status === 200 &&
                dispatch(
                    getButtonDropdown({
                        ticket_id: thunkApi?.data.get("ops_ticket_id"),
                        sub_module: "installations",
                        role_id: getState()?.verifyLogin?.loginData?.user_data?.role_id,
                    })
                )

            if (response.status === 200 && thunkApi.toggle !== undefined) {
                thunkApi.toggle()
            }
            return response.data
        } catch (error: any) {
            dispatch(setAlertFunc({ type: "error", msg: error.message }))
            return thunkApi.rejectWithValue({ error: error.message })
        }
    }
)
const OperationSlice = createSlice({
    name: "EvApplication",
    initialState,
    reducers: {
        setInstallationForm: (state: any, payload: any) => {
            state.installationFormValue = payload?.payload
        },
        setDisplayValues: (state: any, payload: any) => {
            state.displayValues = payload?.payload
        },
        setCurrentRuleValue: (state: any, payload: any) => {
            state.currentRules = payload?.payload
        },
        setVendorDisplayValues: (state: any, payload: any) => {
            state.vendorDisplayValues = payload?.payload
        },
        setVendorCurrentRuleValue: (state: any, payload: any) => {
            state.vendorCurrentRules = payload?.payload
        },
        setStateDisplayValues: (state: any, payload: any) => {
            state.StateDisplayValues = payload?.payload
        },
        setStateCurrentRuleValue: (state: any, payload: any) => {
            state.StateCurrentRules = payload?.payload
        },
        setDealTab: (state: any, payload: any) => {
            state.InstallDealTab = payload?.payload
        },
        setSelectedTab: (state: any, payload: any) => {
            state.selectedTab = payload?.payload
        },
        setEvDealTab: (state: any, payload: any) => {
            state.EVSelectedTab = payload?.payload
        },
        setDemolitionDealTab: (state: any, payload: any) => {
            state.DemolitionSelectedTab = payload?.payload
        },
        setCityDisplayValues: (state: any, payload: any) => {
            state.CityDisplayValues = payload?.payload
        },
        setCityCurrentRuleValue: (state: any, payload: any) => {
            state.CityCurrentRules = payload?.payload
        },
        setChargerDisplayValues: (state: any, payload: any) => {
            state.chargerDisplayValues = payload?.payload
        },
        setChargerCurrentRuleValue: (state: any, payload: any) => {
            state.chargerCurrentRules = payload?.payload
        },
        setEvApplicationForm: (state: any, payload: any) => {
            state.EvApplicationFormValue = payload?.payload
        },
        setEVDisplayValues: (state: any, payload: any) => {
            state.EvDisplayValues = payload?.payload
        },
        setEVCurrentRuleValue: (state: any, payload: any) => {
            state.EvCurrentRules = payload?.payload
        },
        setEVChargerDisplayValues: (state: any, payload: any) => {
            state.EvChargerDisplayValues = payload?.payload
        },
        setEVChargerCurrentRuleValue: (state: any, payload: any) => {
            state.EvChargerCurrentRules = payload?.payload
        },
        setEVStateDisplayValues: (state: any, payload: any) => {
            state.EvStateDisplayValues = payload?.payload
        },
        setEVStateCurrentRuleValue: (state: any, payload: any) => {
            state.EvStateCurrentRules = payload?.payload
        },
        setEVCityDisplayValues: (state: any, payload: any) => {
            state.EvCityDisplayValues = payload?.payload
        },
        setEVCityCurrentRuleValue: (state: any, payload: any) => {
            state.EvCityCurrentRules = payload?.payload
        },
        setAddTicketFormValue: (state: any, payload: any) => {
            state.addTicketFormValue = payload?.payload
        },
        setAddEVTicketFormValue: (state: any, payload: any) => {
            state.addEVTicketFormValue = payload?.payload
        },
        setBlockedOnDisplayValues: (state: any, payload: any) => {
            state.blockedOnDisplayValues = payload?.payload
        },
        setBlockedOnCurrentValues: (state: any, payload: any) => {
            state.blockedOnCurrentValues = payload?.payload
        },
        setSelectedValues: (state: any, payload: any) => {
            state.selectedValue = payload?.payload
        },
        setPages: (state: any, payload: any) => {
            state.page = payload?.payload
        },
        setEvPages: (state: any, payload: any) => {
            state.EvPage = payload?.payload
        },
        setDemolitionForm: (state: any, payload: any) => {
            state.DemolitionFormValue = payload?.payload
        },
        setAddDemolitionTicketForm: (state: any, payload: any) => {
            state.addDemolitionTicketForm = payload?.payload
        },
        setRaisedByDisplayValues: (state: any, payload: any) => {
            state.raisedByDisplayValues = payload?.payload
        },
        setRaisedByCurrentValues: (state: any, payload: any) => {
            state.raisedByCurrentValues = payload?.payload
        },
        setDealDoneByDisplayValues: (state: any, payload: any) => {
            state.dealDoneByDisplayValues = payload?.payload
        },
        setDealDoneByCurrentValues: (state: any, payload: any) => {
            state.dealDoneByCurrentValues = payload?.payload
        },
        setIsBulkMode: (state, action) => {
            state.isBulkMode = action.payload
        },
        setBulkSelectedTicketIds: (state, action) => {
            state.bulkSelectedTicketIds = action.payload
        },
        setBulkSelectAll: (state, action) => {
            state.isBulkSelectAll = action.payload
        },
        resetBulkMappingStates: (state) => {
            state.isBulkSelectAll = false
            state.bulkSelectedTicketIds = []
            state.isBulkMode = false
        }
    },
    extraReducers: (builder: any) => {
        return (
            builder.addCase(getEvApplication.pending, (state: any) => {
                state.EvApplicationLoading = true
                state.is_add_ev_ticket_success = false
            }),
            builder.addCase(getEvApplication.fulfilled, (state: any, { payload }: any) => {
                state.EvApplicationLoading = false
                state.EvApplication = payload
                state.is_add_ev_ticket_success = false
                state.addOpsCommentSuccess = false
                state.editOpsCommentSuccess = false
                state.deleteOpsCommentSuccess = false
            }),
            builder.addCase(getEvApplication.rejected, (state: any) => {
                state.EvApplicationLoading = false
                state.EvApplication = []
            }),
            builder.addCase(getEVTabs.pending, (state: any) => {
                state.EVTabsLoading = true
                state.is_add_ev_ticket_success = false
            }),
            builder.addCase(getEVTabs.fulfilled, (state: any, { payload }: any) => {
                state.EVTabsLoading = false
                state.EVTabsData = payload.message
                state.is_add_ev_ticket_success = false
            }),
            builder.addCase(getEVTabs.rejected, (state: any) => {
                state.EVTabsLoading = false
                state.EVTabsData = []
                state.EvApplicationDetail = []
            }),
            builder.addCase(getEVData.pending, (state: any) => {
                state.EVDataLoading = true
                state.is_add_ticket_success = false
                state.ButtonDropdownData = []
                state.EvApplicationDetail = []
                state.totalPages = 1
            }),
            builder.addCase(getEVData.fulfilled, (state: any, { payload }: any) => {
                state.EVDataLoading = false
                state.EVData = payload
                state.totalPages = payload?.totalPages
                state.is_add_ticket_success = false
            }),
            builder.addCase(getEVData.rejected, (state: any) => {
                state.EVDataLoading = false
                state.EVData = []
                state.totalPages = 0
            }),
            builder.addCase(getDemolitionTabs.pending, (state: any) => {
                state.DemolitionTabsLoading = true
            }),
            builder.addCase(getDemolitionTabs.fulfilled, (state: any, { payload }: any) => {
                state.DemolitionTabsLoading = false
                state.DemolitionTabsData = payload?.data?.message
            }),
            builder.addCase(getDemolitionTabs.rejected, (state: any) => {
                state.DemolitionTabsLoading = false
                state.DemolitionTabsData = []
                state.DealDetailsData = []
            }),
            builder.addCase(getDemolition.pending, (state: any) => {
                state.DemolitionLoading = true
                state.is_add_ticket_success = false
                state.ButtonDropdownData = []
                state.DealDetailsData = []
                state.totalPages = 1
            }),
            builder.addCase(getDemolition.fulfilled, (state: any, { payload }: any) => {
                state.DemolitionLoading = false
                state.DemolitionData = payload
                state.DealDetailsSuccess = true
                state.is_add_ticket_success = false
                state.addOpsCommentSuccess = false
                state.editOpsCommentSuccess = false
                state.deleteOpsCommentSuccess = false
                state.totalPages = payload?.totalPages
            }),
            builder.addCase(getDemolition.rejected, (state: any) => {
                state.DemolitionLoading = false
                state.DemolitionData = []
                state.totalPages = 0
            }),
            builder.addCase(getDemolitionChargers.pending, (state: any) => {
                state.demolitionChargerLoading = true
            }),
            builder.addCase(getDemolitionChargers.fulfilled, (state: any, { payload }: any) => {
                state.demolitionChargerLoading = false
                state.demolitionChargerData = payload?.data
            }),
            builder.addCase(getDemolitionChargers.rejected, (state: any) => {
                state.demolitionChargerLoading = false
                state.demolitionChargerData = []
            }),
            builder.addCase(getDeliveryTemplate.pending, (state: any) => {
                state.DeliveryTemplateLoading = true
            }),
            builder.addCase(getDeliveryTemplate.fulfilled, (state: any, { payload }: any) => {
                state.DeliveryTemplateLoading = false
                state.DeliveryTemplateData = payload
            }),
            builder.addCase(getDeliveryTemplate.rejected, (state: any) => {
                state.DeliveryTemplateLoading = false
                state.DeliveryTemplateData = []
            }),
            builder.addCase(getEvAutofillData.pending, (state: any) => {
                state.evAutofillLoading = true
            }),
            builder.addCase(getEvAutofillData.fulfilled, (state: any, { payload }: any) => {
                state.evAutofillLoading = false
                state.evAutofillData = payload?.data
            }),
            builder.addCase(getEvAutofillData.rejected, (state: any) => {
                state.evAutofillLoading = false
                state.evAutofillData = []
            }),
            builder.addCase(getDealDetails.pending, (state: any) => {
                state.DealDetailsLoading = true
                state.DealDetailsSuccess = false
                state.editSurveySuccess = false
                state.editVendorSuccess = false
                state.editLinkSurveySuccess = false
                state.editRequestLinkSuccess = false
                state.editSkipStatusSuccess = false
            }),
            builder.addCase(getDealDetails.fulfilled, (state: any, { payload }: any) => {
                state.DealDetailsLoading = false
                state.DealDetailsSuccess = true
                state.DealDetailsData = payload
                state.editSurveySuccess = false
                state.editVendorSuccess = false
                state.editLinkSurveySuccess = false
                state.editRequestLinkSuccess = false
                state.editSkipStatusSuccess = false
            }),
            builder.addCase(getDealDetails.rejected, (state: any) => {
                state.DealDetailsLoading = false
                state.DealDetailsData = []
            }),
            builder.addCase(getHoistingAddress.pending, (state: any) => {
                state.HoistingLoading = true
            }),
            builder.addCase(getHoistingAddress.fulfilled, (state: any, { payload }: any) => {
                state.HoistingLoading = false
                state.HoistingData = payload?.data
            }),
            builder.addCase(getHoistingAddress.rejected, (state: any) => {
                state.HoistingLoading = false
                state.HoistingData = []
            }),
            builder.addCase(getDealDetailsButton.pending, (state: any) => {
                state.DealButtonLoading = true
            }),
            builder.addCase(getDealDetailsButton.fulfilled, (state: any, { payload }: any) => {
                state.DealButtonLoading = false
                state.DealButtonData = payload
            }),
            builder.addCase(getDealDetailsButton.rejected, (state: any) => {
                state.DealButtonLoading = false
                state.DealButtonData = []
            }),
            builder.addCase(getVendorList.pending, (state: any) => {
                state.VendorListLoading = true
            }),
            builder.addCase(getVendorList.fulfilled, (state: any, { payload }: any) => {
                state.VendorListLoading = false
                state.VendorList = payload
            }),
            builder.addCase(getVendorCabelingList.pending, (state: any) => {
                state.VendorListLoading = true
            }),
            builder.addCase(getVendorCabelingList.fulfilled, (state: any, { payload }: any) => {
                state.VendorListLoading = false
                state.VendorCabelingList = payload
            }),
            builder.addCase(getVendorCabelingList.rejected, (state: any) => {
                state.VendorListLoading = false
            }),
            builder.addCase(getSurveyLinkList.pending, (state: any) => {
                state.SurveyLinkListLoading = true
            }),
            builder.addCase(getSurveyLinkList.fulfilled, (state: any, { payload }: any) => {
                state.SurveyLinkListLoading = false
                state.SurveyLinkList = payload
            }),
            builder.addCase(getSurveyLinkList.rejected, (state: any) => {
                state.SurveyLinkListLoading = false
            }),
            builder.addCase(getVendorStandList.pending, (state: any) => {
                state.VendorListLoading = true
            }),
            builder.addCase(getVendorStandList.fulfilled, (state: any, { payload }: any) => {
                state.VendorListLoading = false
                state.VendorStandList = payload
            }),
            builder.addCase(getVendorStandList.rejected, (state: any) => {
                state.VendorListLoading = false
            }),
            builder.addCase(getVendorNormalList.pending, (state: any) => {
                state.VendorListLoading = true
            }),
            builder.addCase(getVendorNormalList.fulfilled, (state: any, { payload }: any) => {
                state.VendorListLoading = false
                state.VendorNormalList = payload
            }),
            builder.addCase(getVendorNormalList.rejected, (state: any) => {
                state.VendorListLoading = false
            }),
            builder.addCase(editSurvey.pending, (state: any) => {
                state.editSurveyLoading = true
                state.editSurveySuccess = false
            }),
            builder.addCase(editSurvey.fulfilled, (state: any, { payload }: any) => {
                state.editSurveyLoading = false
                state.editSurveySuccess = true
            }),
            builder.addCase(editSurvey.rejected, (state: any) => {
                state.editSurveyLoading = false
                state.editSurveySuccess = false
            }),
            builder.addCase(editVendor.pending, (state: any) => {
                state.editVendorLoading = true
                state.editVendorSuccess = false
            }),
            builder.addCase(editVendor.fulfilled, (state: any, { payload }: any) => {
                state.editVendorLoading = false
                state.editVendorSuccess = true
            }),
            builder.addCase(editVendor.rejected, (state: any) => {
                state.editVendorLoading = false
                state.editVendorSuccess = false
            }),
            builder.addCase(getHistory.pending, (state: any) => {
                state.HistoryLoading = true
            }),
            builder.addCase(getHistory.fulfilled, (state: any, { payload }: any) => {
                state.HistoryLoading = false
                state.opsHistoryData = payload
            }),
            builder.addCase(getHistory.rejected, (state: any) => {
                state.HistoryLoading = false
            }),
            builder.addCase(getPriceHistory.pending, (state: any) => {
                state.PriceHistoryLoading = true
            }),
            builder.addCase(getPriceHistory.fulfilled, (state: any, { payload }: any) => {
                state.PriceHistoryLoading = false
                state.priceHistoryData = payload
                state.priceTotalCost = payload.total_cost
            }),
            builder.addCase(getPriceHistory.rejected, (state: any) => {
                state.PriceHistoryLoading = false
                state.priceHistoryData = []
            }),
            builder.addCase(getOtherDetails.pending, (state: any) => {
                state.OtherDetailsLoading = true
                state.addSiteDeliveryDateSuccess = false
                state.addDRDetailsSuccess = false
            }),
            builder.addCase(getOtherDetails.fulfilled, (state: any, { payload }: any) => {
                state.OtherDetailsLoading = false
                state.OtherDetailsData = payload
                state.addSiteDeliveryDateSuccess = false
                state.addDRDetailsSuccess = false
            }),
            builder.addCase(getOtherDetails.rejected, (state: any) => {
                state.OtherDetailsLoading = false
                state.OtherDetailsData = []
            }),
            builder.addCase(getEvApplicationDetails.pending, (state: any) => {
                state.EvApplicationDetailLoading = true
                state.EvApplicationDetail = []
                state.editProcSuccess = false
                state.editSkipStatusSuccess = false
            }),
            builder.addCase(getEvApplicationDetails.fulfilled, (state: any, { payload }: any) => {
                state.EvApplicationDetailLoading = false
                state.EvApplicationDetail = payload
                state.editProcSuccess = false
                state.editSkipStatusSuccess = false
            }),
            builder.addCase(getEvApplicationDetails.rejected, (state: any) => {
                state.EvApplicationDetailLoading = false
            }),
            builder.addCase(getCost.pending, (state: any) => {
                state.CostLoading = true
            }),
            builder.addCase(getCost.fulfilled, (state: any, { payload }: any) => {
                state.CostLoading = false
                state.CostData = payload
            }),
            builder.addCase(getCost.rejected, (state: any) => {
                state.CostLoading = false
                state.CostData = []
            }),
            builder.addCase(getScopeWorkStatus.pending, (state: any) => {
                state.ScopeStatusLoading = true
            }),
            builder.addCase(getScopeWorkStatus.fulfilled, (state: any, { payload }: any) => {
                state.ScopeStatusLoading = false
                state.ScopeStatusData = payload
            }),
            builder.addCase(getScopeWorkStatus.rejected, (state: any) => {
                state.ScopeStatusLoading = false
                state.ScopeStatusData = []
            }),
            builder.addCase(getSkipStatuses.pending, (state: any) => {
                state.SkipStatusLoading = true
                state.SkipStatusData = []
            }),
            builder.addCase(getSkipStatuses.fulfilled, (state: any, { payload }: any) => {
                state.SkipStatusLoading = false
                state.SkipStatusData = payload
            }),
            builder.addCase(getSkipStatuses.rejected, (state: any) => {
                state.SkipStatusLoading = false
                state.SkipStatusData = []
            }),
            builder.addCase(editSkipStatus.pending, (state: any) => {
                state.editSkipStatusLoading = true
                state.editSkipStatusSuccess = false
            }),
            builder.addCase(editSkipStatus.fulfilled, (state: any, { payload }: any) => {
                state.editSkipStatusLoading = false
                state.editSkipStatusSuccess = true
            }),
            builder.addCase(editSkipStatus.rejected, (state: any) => {
                state.editSkipStatusLoading = false
                state.editSkipStatusSuccess = false
            }),
            builder.addCase(getButtonDropdown.pending, (state: any) => {
                state.ButtonDropdownLoading = true
                state.ButtonDropdownData = []
            }),
            builder.addCase(getButtonDropdown.fulfilled, (state: any, { payload }: any) => {
                state.ButtonDropdownLoading = false
                state.ButtonDropdownSuccess = true
                state.ButtonDropdownData = payload
            }),
            builder.addCase(getButtonDropdown.rejected, (state: any) => {
                state.ButtonDropdownLoading = false
                state.ButtonDropdownData = []
            }),
            builder.addCase(getBlockedReasons.pending, (state: any) => {
                state.BlockedReasonsLoading = true
            }),
            builder.addCase(getBlockedReasons.fulfilled, (state: any, { payload }: any) => {
                state.BlockedReasonsLoading = false
                state.BlockedReasonsData = payload
            }),
            builder.addCase(getBlockedReasons.rejected, (state: any) => {
                state.ticketRaisedByLoading = false
            }),
            /******************************ADD TICKET*************************** */
            builder.addCase(addTicket.pending, (state: any) => {
                state.is_add_ticket_loading = true
                state.is_add_ticket_success = false
            }),
            builder.addCase(addTicket.fulfilled, (state: any, { payload }: any) => {
                state.is_add_ticket_loading = false
                state.is_add_ticket_success = true
            }),
            builder.addCase(addTicket.rejected, (state: any) => {
                state.is_add_ticket_loading = false
                state.is_add_ticket_success = false
                state.EvApplicationDetail = []
            }),
            /******************************ADD EV APPLICATION TICKET*************************** */
            builder.addCase(addEVTicket.pending, (state: any) => {
                state.is_add_ev_ticket_loading = true
                state.is_add_ev_ticket_success = false
            }),
            builder.addCase(addEVTicket.fulfilled, (state: any, { payload }: any) => {
                state.is_add_ev_ticket_loading = false
                state.is_add_ev_ticket_success = true
            }),
            builder.addCase(addEVTicket.rejected, (state: any) => {
                state.is_add_ev_ticket_loading = false
                state.is_add_ev_ticket_success = false
                // state.EvApplicationDetail = []
            }),
            /******************************ADD Demolition TICKET*************************** */
            builder.addCase(addDemolitionTicket.pending, (state: any) => {
                state.is_add_Demolition_ticket_loading = true
                state.is_add_Demolition_ticket_success = false
            }),
            builder.addCase(addDemolitionTicket.fulfilled, (state: any, { payload }: any) => {
                state.is_add_Demolition_ticket_loading = false
                state.is_add_Demolition_ticket_success = true
            }),
            builder.addCase(addDemolitionTicket.rejected, (state: any) => {
                state.is_add_Demolition_ticket_loading = false
                state.is_add_Demolition_ticket_success = false
                // state.EvApplicationDetail = []
            }),
            /// ev application data
            builder.addCase(editEvSurvey.pending, (state: any) => {
                state.editProcLoading = true
                state.editProcSuccess = false
            }),
            builder.addCase(editEvSurvey.fulfilled, (state: any, { payload }: any) => {
                state.editProcLoading = false
                state.editProcSuccess = true
            }),
            builder.addCase(editEvSurvey.rejected, (state: any) => {
                state.editProcLoading = false
                state.editProcSuccess = false
            }),
            builder.addCase(editTicketDetails.pending, (state: any) => {
                state.editTicketDetailsLoading = true
            }),
            builder.addCase(editTicketDetails.fulfilled, (state: any, { payload }: any) => {
                state.editTicketDetailsLoading = false
            }),
            builder.addCase(editTicketDetails.rejected, (state: any) => {
                state.editTicketDetailsLoading = false
            }),
            builder.addCase(editOtherDetails.pending, (state: any) => {
                state.editOtherDetailsLoading = true
            }),
            builder.addCase(editOtherDetails.fulfilled, (state: any, { payload }: any) => {
                state.editOtherDetailsLoading = false
                state.editOtherDetailSuccess = true
            }),
            builder.addCase(editOtherDetails.rejected, (state: any) => {
                state.editOtherDetailsLoading = false
                state.editOtherDetailSuccess = false
            }),
            builder.addCase(addDRDetails.pending, (state: any) => {
                state.addDRDetailsLoading = true
            }),
            builder.addCase(addDRDetails.fulfilled, (state: any, { payload }: any) => {
                state.addDRDetailsLoading = false
                state.addDRDetailsSuccess = true
            }),
            builder.addCase(addDRDetails.rejected, (state: any) => {
                state.addDRDetailsLoading = false
                state.addDRDetailsSuccess = false
            })
        )
    },
})

export const {
    setInstallationForm,
    setDisplayValues,
    setEvApplicationForm,
    setCurrentRuleValue,
    setChargerCurrentRuleValue,
    setAddEVTicketFormValue,
    setChargerDisplayValues,
    setEVDisplayValues,
    setEVCurrentRuleValue,
    setEVChargerCurrentRuleValue,
    setEVChargerDisplayValues,
    setAddTicketFormValue,
    setVendorCurrentRuleValue,
    setVendorDisplayValues,
    setStateCurrentRuleValue,
    setStateDisplayValues,
    setCityCurrentRuleValue,
    setCityDisplayValues,
    setEVCityCurrentRuleValue,
    setEVCityDisplayValues,
    setEVStateCurrentRuleValue,
    setDealTab,
    setEvDealTab,
    setEVStateDisplayValues,
    setBlockedOnDisplayValues,
    setBlockedOnCurrentValues,
    setSelectedTab,
    setSelectedValues,
    setPages,
    setEvPages,
    setDemolitionForm,
    setDemolitionDealTab,
    setAddDemolitionTicketForm,
    setRaisedByCurrentValues,
    setRaisedByDisplayValues,
    setDealDoneByCurrentValues,
    setDealDoneByDisplayValues,
    setIsBulkMode,
    setBulkSelectedTicketIds,
    setBulkSelectAll,
    resetBulkMappingStates
} = OperationSlice.actions
export const getAllEvApplication = (state: any) => state.EvApplication
export const getOpsAllComments = (state: any) => state.operation.comments

export default OperationSlice.reducer
