import axios from "axios"
import { api } from "../../../../api"
import { setAlertFunc } from "../../../../components/AlertToaster/AlertSlice"
import {
    HistoryResponse,
    IResponse,
    StatusButton,
    TabsResponseType,
    TicketDataResponseType,
} from "../../../Common/commonTypes"
import { PresignedUrl, submitFinalUploads } from "../../../../utils/utils"

const MODULE_URL = "operations/survey"

type EditImageRequest = {
    ticket_id: string
    removed_ids: number[]
    files: {
        file_type: string
        file_name: any
    }[]
}

type EditImageResponse = {
    message: string
    removed_doc_ids: number[]
    urls: { urls: PresignedUrl[]; file_type: string }[]
}

const surveyService = api.injectEndpoints({
    endpoints: build => ({
        // Queries
        getSurveyTabs: build.query({
            query: (params: { data: any; page: number; selectedValue: number }) => ({
                url: `${MODULE_URL}/total`,
                params: {
                    state_id: JSON.stringify(params.data.stateId ?? []),
                    status_ids: JSON.stringify(params.data.statusId ?? []),
                    city_id: JSON.stringify(params.data.cityId ?? []),
                    assigned_to: params.data.assigned_to?.id ?? "",
                    age: JSON.stringify(params.data.age ?? []),
                    age_op: params.data.age_op ?? "",
                    charger_type: JSON.stringify(params.data.chargerIds ?? []),
                    search_key: params.data.search_key ?? "",
                    read_filter: params.data.ReadUnread ?? "all",
                    per_page: params?.selectedValue,
                    page: params?.page,
                    vendor_ids: JSON.stringify(params.data.vendor_ids ?? []),
                    type: params.data.ticketType ?? "all",
                    no_of_spots: params.data.no_of_spots ?? "",
                },
            }),
            transformResponse: (response: TabsResponseType) => response.data.message,
            providesTags: ["Survey"],
        }),
        getSurveyData: build.query<TicketDataResponseType, { data: any; page: number; selectedValue: number }>({
            query: params => ({
                url: `${MODULE_URL}/${params.data.parent_status ?? "all_tab"}`,
                params: {
                    state_id: JSON.stringify(params.data.stateId ?? []),
                    status_ids: JSON.stringify(params.data.statusId ?? []),
                    city_id: JSON.stringify(params.data.cityId ?? []),
                    assigned_to: params.data.assigned_to?.id ?? "",
                    age: JSON.stringify(params.data.age ?? []),
                    age_op: params.data.age_op ?? "",
                    charger_type: JSON.stringify(params.data.chargerIds ?? []),
                    search_key: params.data.search_key ?? "",
                    read_filter: params.data.ReadUnread ?? "all",
                    per_page: params?.selectedValue,
                    page: params?.page,
                    vendor_ids: JSON.stringify(params.data.vendor_ids ?? []),
                    type: params.data.ticketType ?? "all",
                    no_of_spots: params.data.no_of_spots ?? "",
                },
            }),
            providesTags: ["Survey"],
        }),
        getSurveyTicketDetails: build.query<IResponse<any>, { ticket_id: number | string }>({
            query: params => ({
                url: `${MODULE_URL}/ticket_details`,
                params: params,
            }),
            providesTags: (result, error, arg) => [{ type: "Survey", id: arg.ticket_id }],
        }),
        getSurveyTicketHistory: build.query<HistoryResponse, { ticket_id: string }>({
            query: params => ({
                url: `${MODULE_URL}/history`,
                params: params,
            }),
            providesTags: (result, error, arg) => [{ type: "Survey", id: arg.ticket_id }],
        }),
        getSurveyButtonDropwdown: build.query<IResponse<StatusButton[]>, { ticket_id: string }>({
            query: params => ({
                url: `${MODULE_URL}/button_dropdown`,
                params: params,
            }),
            providesTags: (result, error, arg) => [{ type: "Survey", id: arg.ticket_id }],
        }),
        dropSurveyTicket: build.query<IResponse<string>, { ticket_id: string; reason: string }>({
            query: params => ({
                url: `${MODULE_URL}/drop_ticket`,
                params,
            }),
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled
                    dispatch(setAlertFunc({ type: "success", msg: data?.data }))
                    dispatch(surveyService.util.invalidateTags(["Survey"]))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error?.message }))
                }
            },
        }),

        // Mutations
        addSurveyTicket: build.mutation<IResponse<{ msg: string; ticket_id: number }>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/ticket_details`,
                method: "POST",
                body: body,
            }),
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled
                    dispatch(setAlertFunc({ type: "success", msg: data?.data.msg }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error?.message }))
                }
            },
            invalidatesTags: ["Survey"],
        }),
        editSurveyTicket: build.mutation<IResponse<string[]>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/ticket_details`,
                method: "PUT",
                body: body,
            }),
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled
                    dispatch(setAlertFunc({ type: "success", msg: data?.data[0] }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error?.message }))
                }
            },
            invalidatesTags: ["Survey", "Installation"],
        }),
        // Mutation to edit survey images and upload them to s3 bucket
        editSurveyImages: build.mutation<
            EditImageResponse,
            { data: EditImageRequest; fileData: File[]; opsId: string }
        >({
            query: body => ({
                url: `/edit_documents`,
                method: "PUT",
                body: body?.opsId
                    ? {
                          ...body?.data,
                          ticket_id: body?.opsId,
                      }
                    : body?.data,
            }),
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled
                    if (data.urls.length) {
                        let promises: Promise<any>[] = []
                        let responseUrl = data.urls?.[0].urls
                        responseUrl?.forEach(async (url, index) => {
                            const promise = axios.put(url.presigned_url, arg?.fileData[index], {
                                headers: {
                                    "Content-Type": arg?.fileData[index].type,
                                },
                            })
                            promises.push(promise)
                        })
                        await Promise.all(promises)
                        let finalPayload = {
                            ticket_id: arg.data.ticket_id,
                            file_infos: data.urls?.[0]?.urls?.map((i: any) => ({
                                file_url: i.file_url[0],
                                report_id: i.report_id,
                                doc_id: i.doc_id,
                            })),
                        }
                        await submitFinalUploads(finalPayload)
                    }
                    //Manual tag invalidation to prevent ticket details api call before s3 upload
                    dispatch(surveyService.util.invalidateTags(["Survey"]))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error?.message }))
                }
            },
        }),
        editSurveyTicketStatus: build.mutation<IResponse<string[]>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/ticket_put`,
                method: "PUT",
                body,
            }),
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled
                    dispatch(setAlertFunc({ type: "success", msg: data?.data[0] }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error?.message }))
                }
            },
            invalidatesTags: ["Survey", "Installation"],
        }),
        // Mutation for skiping status currently unused
        skipToStatusSurvey: build.mutation({
            query: body => ({
                url: `${MODULE_URL}/skip_to_status`,
                method: "PUT",
                body,
            }),
        }),
    }),
})

export const {
    useLazyGetSurveyTabsQuery,
    useLazyGetSurveyDataQuery,
    useLazyDropSurveyTicketQuery,

    useGetSurveyTicketDetailsQuery,
    useGetSurveyTicketHistoryQuery,
    useGetSurveyButtonDropwdownQuery,

    useAddSurveyTicketMutation,
    useEditSurveyTicketMutation,
    useEditSurveyImagesMutation,
    useEditSurveyTicketStatusMutation,
} = surveyService

export { surveyService }
