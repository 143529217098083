import { api } from "../../../../api"
import { setAlertFunc } from "../../../../components/AlertToaster/AlertSlice"
import { IResponse, StatusTabsData, TicketDataResponseType } from "../../../Common/commonTypes"

type AddressObj = {
    access_type: string
    latitude: number
    longitude: number
    ops_ticket_id: number
    pincode: number
    project_id: number
    project_name: string
    property_type: string
    site_id: string
}
type GetSkipStatusesParams = {
    search_key?: string
    ticket_id: string
    sub_module: string
}
const MODULE_URL = "operations"

const EVApplicationService = api.injectEndpoints({
    endpoints: build => ({
        getEVTabs: build.query({
            query: params => ({
                url: `${MODULE_URL}/ev_total_tickets`,
                params: {
                    state_id: JSON.stringify(params.data.stateId ?? []),
                    status_id: JSON.stringify(params.data.statusId ?? []),
                    city_id: JSON.stringify(params.data.cityId ?? []),
                    assigned_to: params.data.assigned_to?.id ?? "",
                    age: JSON.stringify(params.data.age ?? []),
                    age_op: params.data.age_op ?? "",
                    raised_on_end: params.data.TicketEndDate ?? "",
                    raised_on_start: params.data.TicketStartDate ?? "",
                    updated_on_start: params.data.UpdateStartDate ?? "",
                    updated_on_end: params.data.UpdateEndDate ?? "",
                    charger_type: JSON.stringify(params.data.chargerIds ?? []),
                    ev_ticket_type: params.data.ticketType ?? "all",
                    search_key: params.data.search_key ?? "",
                    read_filter: params.data.ReadUnread ?? "all",
                    per_page: params?.selectedValue,
                    page: params?.page,
                },
            }),
            transformResponse: (response: { message: StatusTabsData[] }) => response.message,
            providesTags: ["EvApplication"],
        }),
        getEVData: build.query<TicketDataResponseType, { data: any; selectedValue: number; page: number }>({
            query: params => ({
                url: `${MODULE_URL}/ev_application/${params.data.parent_status ?? "all_tab"}`,
                params: {
                    state_id: JSON.stringify(params.data.stateId ?? []),
                    status_id: JSON.stringify(params.data.statusId ?? []),
                    city_id: JSON.stringify(params.data.cityId ?? []),
                    assigned_to: params.data.assigned_to?.id ?? "",
                    age: JSON.stringify(params.data.age ?? []),
                    age_op: params.data.age_op ?? "",
                    raised_on_end: params.data.TicketEndDate ?? "",
                    raised_on_start: params.data.TicketStartDate ?? "",
                    updated_on_start: params.data.UpdateStartDate ?? "",
                    updated_on_end: params.data.UpdateEndDate ?? "",
                    charger_type: JSON.stringify(params.data.chargerIds ?? []),
                    ev_ticket_type: params.data.ticketType ?? "all",
                    search_key: params.data.search_key ?? "",
                    read_filter: params.data.ReadUnread ?? "all",
                    per_page: params?.selectedValue,
                    page: params?.page,
                },
            }),
            providesTags: ["EvApplication"],
        }),
        getRaisedByUsers: build.query<{ id: number; name: string }[], void>({
            query: () => ({
                url: `${MODULE_URL}/raised_by_users`,
            }),
            providesTags: ["EvApplication"],
        }),
        getEvAutofillData: build.query<AddressObj, { id: number | string }>({
            query: ({ id }) => ({
                url: `ev_solo_fill`,
                params: {
                    ops_ticket_id: id ?? "",
                },
            }),
            transformResponse: (response: IResponse<any>) => response.data,
        }),
        getHoistingAddress: build.query<IResponse<any>, { state_id: number; city_id: number }>({
            query: obj => ({
                url: `hosting_address`,
                params: {
                    state_id: obj?.state_id ?? "",
                    city_id: obj?.city_id ?? "",
                },
            }),
        }),
        getEvApplicationDetails: build.query<IResponse<any>, { ticketId: string }>({
            query: ({ ticketId }) => ({
                url: `${MODULE_URL}/ev_application_detail`,
                params: {
                    ticket_id: ticketId ?? "",
                },
            }),
            providesTags: (result, error, arg) => ["EvApplication", { type: "EvApplication", id: arg.ticketId }],
        }),
        getOpsSkipStatuses: build.query<IResponse<any>, GetSkipStatusesParams>({
            query: obj => ({
                url: `${MODULE_URL}/statuses`,
                params: {
                    search_key: obj.search_key ?? "",
                    ticket_id: obj.ticket_id ?? "",
                    sub_module: obj.sub_module ?? "",
                },
            }),
        }),
        // Mutations
        addEVTicket: build.mutation<IResponse<string>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/ev_application`,
                method: "POST",
                body: body,
            }),
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled
                    dispatch(setAlertFunc({ type: "success", msg: "Ticket Created!" }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error?.message }))
                }
            },
            invalidatesTags: ["EvApplication"],
        }),
        editEvSurvey: build.mutation<IResponse<string>, FormData>({
            query: body => ({
                url: `${MODULE_URL}/ev_application`,
                method: "PUT",
                body: body,
            }),
            onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled
                    dispatch(setAlertFunc({ type: "success", msg: data?.data }))
                } catch (error: any) {
                    dispatch(setAlertFunc({ type: "error", msg: error?.message }))
                }
            },
            invalidatesTags: ["EvApplication"],
        }),
        OpsEditSkipStatus: build.mutation({
            query: body => ({
                url: `${MODULE_URL}/skip_to_status`,
                method: "PUT",
                body,
            }),
            async onQueryStarted(params, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled
                    dispatch(
                        setAlertFunc({
                            type: data.message !== "Access denied" ? "success" : "error",
                            msg: data?.message,
                        })
                    )
                } catch (error) {
                    dispatch(setAlertFunc({ type: "error", msg: "Failed to fetch ticket details" }))
                }
            },
            invalidatesTags: ["EvApplication", "Installation"],
        }),
    }),
})

export const {
    useLazyGetEVTabsQuery,
    useLazyGetEVDataQuery,
    useGetRaisedByUsersQuery,
    useAddEVTicketMutation,
    useEditEvSurveyMutation,
    useOpsEditSkipStatusMutation,
    useLazyGetHoistingAddressQuery,
    useGetEvApplicationDetailsQuery,
    useLazyGetEvAutofillDataQuery,
    useGetOpsSkipStatusesQuery,
} = EVApplicationService

export { EVApplicationService }
